import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { apiCall } from "../../api";

import MenuDej from "../menu/MenuDej";
import MenuBoite from "../menu/MenuBoite";
import MenuRepas from "../menu/MenuRepas";
import MenuJour from "../menu/MenuJour";

class BossMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      menu: "",
      price: 0,
      description: "",
      category: "",
      reload: false,
      alert: null
    };
  }

  componentDidMount() {
    // this.props.scrollUp();
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  selectMenuType = type => {
    this.setState({
      menu: type
    });
  };

  createMeal = async () => {
    if (this.state.name.length === 0 || this.state.menu.length === 0) {
      alert("Boss, selectionnez un type de menu et entrez un nom");
      return;
    }
    let data = {
      itemType: "Meal",
      info: {
        ...this.state
      }
    };
    await apiCall("post", `/api/menu`, data)
      .then(res => {
        const alert = {
          type: "success",
          message: "Item menu ajouté!"
        };

        this.setState({
          name: "",
          menu: "",
          price: 0,
          description: "",
          category: "",
          reload: !this.state.reload
        });

        this.setAlert(alert);
      })
      .catch(err => {
        const alert = {
          type: "error",
          message: "Échec à l'ajout de l'item menu"
        };

        this.setState({
          name: "",
          menu: "",
          price: 0,
          description: "",
          category: "",
          reload: !this.state.reload
        });

        this.setAlert(alert);
      });
  };

  setAlert = async alert => {
    await this.setState({ alert });
    setTimeout(() => {
      this.setState({
        alert: null
      });
    }, 5000);
  };

  render() {
    let { menu, alert } = this.state;

    let alertModel;
    if (alert !== null) {
      alertModel = (
        <div
          className={`alert ${alert.type === "success" ? "success" : "error"}`}
        >
          {alert.type === "success" ? (
            <FontAwesomeIcon icon="check" />
          ) : (
            <FontAwesomeIcon icon="times" />
          )}
          <p>{alert.message}</p>
        </div>
      );
    }

    let options;
    switch (menu) {
      case "dej":
        options = (
          <select
            name="category"
            value={this.state.category}
            onChange={this.handleChange}
          >
            <option>Select</option>
            <option value="classique">classique</option>
            <option value="omelette">omelettes</option>
            <option value="cassolettes">cassolettes</option>
            <option value="droleMatin">drôle de matin</option>
            <option value="extra">petites faims</option>
            <option value="brevages">brevages</option>
          </select>
        );
        break;

      case "repas":
        options = (
          <select
            name="category"
            value={this.state.category}
            onChange={this.handleChange}
          >
            <option>Select</option>
            <option value="classique">classique</option>
            <option value="entree">entrée</option>
            <option value="salade">salade</option>
            <option value="frite">frite et poutine</option>
            <option value="pizza">sur une croute</option>
            <option value="gyros">gyros et souvlakis</option>
            <option value="sandwich">entre 2 pains</option>
          </select>
        );
        break;

      case "jour":
        options = (
          <select
            name="category"
            value={this.state.category}
            onChange={this.handleChange}
          >
            <option>Select</option>
            <option value="soupe">soupe</option>
            <option value="principal">repas</option>
            <option value="dessert">dessert</option>
            {/*<option value="salade">salade</option>*/}
          </select>
        );
        break;

      case "boite":
        options = (
          <select
            name="category"
            value={this.state.category}
            onChange={this.handleChange}
          >
            <option>Select</option>
            <option value="sandwich">sandwich</option>
            <option value="salade">salade</option>
            <option value="accompagnement">accompagnement</option>
            <option value="rechauffe">rechauffe</option>
          </select>
        );
        break;

      default:
        options = null;
    }

    return (
      <div className="boss-menu">
        {/*<div className="boss-main">*/}

        <div className="boss-preview">
          <h1>Modifications menu</h1>

          {alert !== null ? alertModel : null}

          {/*<h1>Mes menus</h1>*/}

          <h2>menu du jour</h2>
          <MenuJour
            reload={this.state.reload}
            isAutenticated={this.props.isAutenticated}
          />
          <h2>menu boîte</h2>
          <MenuBoite
            reload={this.state.reload}
            isAutenticated={this.props.isAutenticated}
          />
          <h2>menu déjeuner</h2>
          <MenuDej
            reload={this.state.reload}
            isAutenticated={this.props.isAutenticated}
          />
          <h2>menu repas</h2>
          <MenuRepas
            reload={this.state.reload}
            isAutenticated={this.props.isAutenticated}
          />
        </div>

        {/*<div className="boss-create-col">*/}
        <div className="boss-main-create">
          <h3>Créer un item menu</h3>

          <div className="boss-create-cont boss-input-menu">
            <h5>Sélectionnez un type de menu</h5>
            <ul className="boss-menu-input">
              <li
                className={this.state.menu === "dej" ? "selected" : null}
                onClick={() => this.selectMenuType("dej")}
              >
                <h5>Dejeuner</h5>
              </li>
              <li
                className={this.state.menu === "repas" ? "selected" : null}
                onClick={() => this.selectMenuType("repas")}
              >
                <h5>Diner</h5>
              </li>
              <li
                className={this.state.menu === "jour" ? "selected" : null}
                onClick={() => this.selectMenuType("jour")}
              >
                <h5>Du jour</h5>
              </li>
              <li
                className={this.state.menu === "boite" ? "selected" : null}
                onClick={() => this.selectMenuType("boite")}
              >
                <h5>Boite</h5>
              </li>
              <li
                className={this.state.menu === "boisson" ? "selected" : null}
                onClick={() => this.selectMenuType("boisson")}
              >
                <h5>Boisson</h5>
              </li>
              <li
                className={this.state.menu === "desserts" ? "selected" : null}
                onClick={() => this.selectMenuType("desserts")}
              >
                <h5>Desserts</h5>
              </li>
              <li
                className={this.state.menu === "extra" ? "selected" : null}
                onClick={() => this.selectMenuType("extra")}
              >
                <h5>Extra</h5>
              </li>
            </ul>
          </div>

          {menu === "dej" ||
          menu === "repas" ||
          menu === "jour" ||
          menu === "boite" ? (
            <div className="boss-create-cont">
              <h5>Sélectionner une catégorie</h5>
              {options}
            </div>
          ) : null}

          <div className="boss-create-cont boss-input-name">
            <h5>Nom</h5>
            <input
              className="boss-create-input"
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>

          <div className="boss-create-cont boss-input-price">
            <h5>Prix</h5>
            <input
              className="boss-create-input"
              type="number"
              min={0}
              name="price"
              value={this.state.price}
              onChange={this.handleChange}
            />
          </div>

          <div className="boss-create-cont boss-input-desc">
            <h5>Description (Optional)</h5>
            <textarea
              className="boss-create-input"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>

          <button className="boss-create-btn" onClick={this.createMeal}>
            Créer!
          </button>
        </div>
        {/*</div>*/}

        {/*</div>*/}
      </div>
    );
  }
}

export default BossMenu;
