import React from "react";

export default function Horraire(props) {
  return (
    <div className="schedule">
      <ul>
        <li className="sch-timing">
          <h4>L</h4>
          <h4>11</h4>
        </li>
        <li className="sch-timing">
          <h4>M</h4>
          <h4>11</h4>
        </li>
        <li className="sch-timing">
          <h4>M</h4>
          <h4>11</h4>
        </li>
        <li className="sch-timing">
          <h4>J</h4>
          <h4>11</h4>
        </li>
        <li className="sch-timing">
          <h4>V</h4>
          <h4>11</h4>
        </li>
        <li className="sch-timing">
          <h4>S</h4>
          {/*<h4>7</h4>*/}
        </li>
        <li className="sch-timing">
          <h4>D</h4>
          {/*<h4>7</h4>*/}
        </li>
      </ul>
      <div className="schedule-line" />
      <ul>
        <li className="sch-close">
          <h4>14h30</h4>
        </li>
        <li className="sch-close">
          <h4>14h30</h4>
        </li>
        <li className="sch-close">
          <h4>14h30</h4>
        </li>
        <li className="sch-close">
          <h4>14h30</h4>
        </li>
        <li className="sch-close">
          <h4>14h30</h4>
        </li>
        <li className="sch-close sd">
          <h4>Sur demande</h4>
        </li>
        <li className="sch-close sd">
          <h4>Sur demande</h4>
        </li>
      </ul>
    </div>
  );
}
