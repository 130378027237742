import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { apiCall } from "../../api";

import Horraire from "./Horraire";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  submitEmail = () => {
    apiCall("put", "/api/contact", { email: this.state.email })
      .then(res => alert("email enregistré!"))
      .catch(err => console.log(err.message));

    this.setState({
      email: ""
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <div className="footer">
        <div className="footer-cont">
          <div className="footer-column">
            <h3 className="footer-title">reservation</h3>
            <a href={"mailto:resto.lenikoli@gmail.com"}>
              <h5>resto.lenikoli@gmail.com</h5>
            </a>
            <a href={"tel:1 418-681-2411"}>
              <h5>1 418-681-2411</h5>
            </a>

            <h3 className="footer-title">addresse</h3>
            <h5>2014 Rue Cyrille-Duquet, suite 115</h5>
            <h5>Québec, QC G1N 4N6</h5>
            <h5>
              <a
                href={
                  "https://www.google.ca/maps/place/2014+Rue+Cyrille-Duquet,+Qu%C3%A9bec,+QC+G1N+4N6/@46.796289,-71.2752728,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb896e526bf8bdf:0x68365bcf7c0bd2ad!8m2!3d46.796289!4d-71.2730841"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Open in Google Map
              </a>
            </h5>
          </div>

          <div className="footer-column horaire">
            <h3 className="footer-title">
              heures
              <br />
              d'ouverture
            </h3>
            <Horraire />
          </div>

          <div className="footer-column">
            <h3 className="footer-title">recevez nos promotions</h3>
            <h5>
              Inscrivez-vous et soyez au fait de nos promotions et menus du
              jour!
            </h5>
            <div className="footer-email-input">
              <input
                id="email"
                placeholder="joe.blow@youremail.ca"
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              <label htmlFor="email"></label>
              <button onClick={this.submitEmail}>Envoyer</button>
            </div>
          </div>
        </div>

        {/*<div>*/}
        <div className="footer-social-media-cont">
          <div className="icons-cont">
            <a
              href={
                "https://www.facebook.com/pages/category/Diner/Le-Nikoli-288697098398387/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {/*<FontAwesomeIcon icon={["fab", "instagram"]} />*/}
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </a>
            <a
              className="uber-icon"
              href={
                "https://www.ubereats.com/ca/quebec-city/food-delivery/le-nikoli/CcejC3_MS1aUwoA5lYKSMA"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Uber-eats"
                src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/uber.png"
              />
            </a>
          </div>
          {/*</div>*/}
          <p>Ce site est une production de InlineDev</p>
        </div>
      </div>
    );
  }
}

export default Footer;
