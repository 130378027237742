import React, { Component } from "react";

class Contact extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div className="contact">
        <div className="footer-contact-cont">
          <h3>Pour toutes questions, contactez-nous</h3>
          <h5>
            Par téléphone au: <a href={"tel:1 418-681-2411"}>418 681-2411</a>
          </h5>
          <h5>
            Courriel du Nikoli:{" "}
            <a href={"mailto:resto.lenikoli@gmail.com"}>
              resto.lenikoli@gmail.com
            </a>
          </h5>
          <h5>
            Courriel des Bols à Pat:{" "}
            <a href={"mailto:lesbols.pat@gmail.com"}>
              lesbols.pat@gmail.com
            </a>
          </h5>
          <h5>ou</h5>
          <h5>encore mieux, en personne!</h5>
          <h5>
            <a
              href={
                "https://www.google.ca/maps/place/2014+Rue+Cyrille-Duquet,+Qu%C3%A9bec,+QC+G1N+4N6/@46.796289,-71.2752728,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb896e526bf8bdf:0x68365bcf7c0bd2ad!8m2!3d46.796289!4d-71.2730841"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              2014 Rue Cyrille-Duquet, suite 115, Québec, QC G1N 4N6
            </a>
          </h5>
          <a
            className="uber-icon"
            href={
              "https://www.ubereats.com/ca/quebec-city/food-delivery/le-nikoli/CcejC3_MS1aUwoA5lYKSMA"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Uber-eats"
              src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/uber.png"
            />
          </a>
        </div>

        <img
          alt="LeNikoli_Map"
          src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/map.png"
        />
      </div>
    );
  }
}

export default Contact;
