import React, { Component } from "react";

import MenuBoite from "../menu/MenuBoite";

class Boite extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div>
        <div className="side-menu" />

        <div className="menu-header bols">
          <div className="overlay">
            <h1>
              les
              <br />
              <span>Bols à Pat</span>
            </h1>
          </div>
          {/* <img
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/raphael-nogueira-Znvxeud6sDc-unsplash.jpg"
          /> */}
        </div>

        <div className="menu-cont">
          <div className="menu-cont-fade" />
          <MenuBoite isAutenticated={this.props.isAutenticated} />
        </div>

        <div></div>
      </div>
    );
  }
}

export default Boite;
