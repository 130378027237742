import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import withAuth from '../hocs/withAuth';

import BossMenu from './BossMenu';
import BossContact from './BossContact';
import BossContent from './BossContent';


class BossMain extends Component{
  constructor(props){
    super(props);
    this.state = {
      menu: ''
    }
  }

  render(){

    return(
      <div className="boss-cont">

        <Switch>
          <Route path="/cestmoileboss/menu" render={props =>
              <BossMenu isAutenticated={this.props.isAutenticated}/>
            }/>
          <Route path="/cestmoileboss/contenu" render={props =>
              <BossContent isAutenticated={this.props.isAutenticated}/>
            }/>
          <Route path="/cestmoileboss/contact" render={props =>
              <BossContact isAutenticated={this.props.isAutenticated}/>
            }/>

        </Switch>



      </div>
    )
  }
}

export default withRouter(withAuth(BossMain));
