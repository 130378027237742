import React, { Component } from "react";

class Landing extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div>
        <div className="main-overlay">
          <img
            className="main-back"
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/miguel-andrade-R3f2emOt1bU-unsplash-min.jpg"
          />
          <img
            className="main-pic-overlay"
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/NIKOLI_Cuisine_Familiale_blanc.png"
          />
        </div>
      </div>
    );
  }
}

export default Landing;
