import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { apiCall } from "../../api";

class MenuRepas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      reload: this.props.reload
    };
  }

  componentDidMount() {
    this.getMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.props.reload) {
      this.getMenu();
    }
  }

  getMenu = async () => {
    let data = await apiCall("get", "/api/menu?type=repas");
    this.setState({
      menu: data,
      reload: false
    });
  };

  removeItem = async id => {
    await apiCall("delete", `/api/menu?id=${id}`);
  };

  render() {
    let { menu } = this.state;

    let parsedMenu = {};
    parsedMenu.frite = [];
    parsedMenu.gyros = [];
    parsedMenu.sandwich = [];
    parsedMenu.pizza = [];
    parsedMenu.salade = [];
    parsedMenu.extra = [];
    parsedMenu.entree = [];
    parsedMenu.brevages = [];
    parsedMenu.classique = [];

    menu.map(m =>
      parsedMenu[m.category].push(
        <li key={menu.indexOf(m)}>
          {this.props.isAutenticated ? (
            <FontAwesomeIcon
              onClick={() => this.removeItem(m._id)}
              icon="times"
            />
          ) : null}
          <div className="menu-item-head">
            <h4>{m.name}</h4>
            <h5>{m.price}</h5>
          </div>
          <p>{m.description}</p>
        </li>
      )
    );

    return (
      <div>
        <div className="menu-cat-cont">
          <h3>Les petites faims</h3>
          <ul>{parsedMenu.entree}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Les salades</h3>
          <ul>{parsedMenu.salade}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Nos classiques</h3>
          <ul>{parsedMenu.classique}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Frites et poutines</h3>
          <ul>{parsedMenu.frite}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Sur la croute</h3>
          <ul>{parsedMenu.pizza}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Gyros et souvlakis</h3>
          <ul>{parsedMenu.gyros}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Entre 2 pains</h3>
          <ul>{parsedMenu.sandwich}</ul>
        </div>

        {/*<div className="menu-cat-cont">
          <h3>Les petites faims</h3>
          <ul>{parsedMenu.extra}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Les boissons</h3>
          <ul>{parsedMenu.brevages}</ul>
        </div>*/}
      </div>
    );
  }
}

export default MenuRepas;
