import React, { Component } from "react";

import MenuRepas from "../menu/MenuRepas";
import MenuJour from "../menu/MenuJour";

class Repas extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div>
        <div className="side-menu" />

        <div className="menu-header">
          <div className="overlay">
            <h1>
              les
              <br />
              <span>repas</span>
            </h1>
          </div>
          <img
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/erik-odiin-F_xGk7V0Xbc-unsplash.jpg"
          />
        </div>

        <div className="menu-cont">
          <div className="menu-cont-fade" />
          <h2>Menu du jour</h2>
          <MenuJour isAutenticated={this.props.isAutenticated} />
        </div>

        <div className="menu-cont">
          <h2>Menu à la carte</h2>
          <MenuRepas isAutenticated={this.props.isAutenticated} />
        </div>
      </div>
    );
  }
}

export default Repas;
