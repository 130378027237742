import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import jwtDecode from 'jwt-decode';
import { setTokenHeader } from './api';

import Nav from './component/navbar/Nav';
import Main from './component/main/Main';
import Footer from './component/footer/Footer';

library.add(fas,fab)

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isAutenticated: false,
      userId: ''
    }
  }
  

  componentDidMount(){
    if(localStorage.jwtToken){
      setTokenHeader(localStorage.jwtToken);
      let decoded = jwtDecode(localStorage.jwtToken);
      this.setState({
        isAutenticated: true,
        userId: decoded.id
      });
    }
  }

  setUser = userData => {
    localStorage.setItem('jwtToken', userData.token);
    setTokenHeader(userData.token);
    this.setState({
      isAutenticated: true,
      userId: userData.id
    });
  }

  logOut = () =>{
    this.setState({
      isAutenticated: false,
      id: ''
    });
    localStorage.clear();
  }

  render(){

    return (
      <Router>
        <div className="App">
          <Nav isAutenticated={this.state.isAutenticated} logOut={this.logOut}/>
          <Main isAutenticated={this.state.isAutenticated} userId setUser={this.setUser}/>
          <Footer/>
        </div>
      </Router>
    );
  }
}



export default App;
