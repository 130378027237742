import React, { Component } from "react";

import MenuDej from "../menu/MenuDej";

class Dej extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div>
        <div className="side-menu" />

        <div className="menu-header">
          <div className="overlay">
            <h1>
              les
              <br />
              <span>déjeuners</span>
            </h1>
          </div>
          <img
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/leti-kugler-I-ykyShydj0-unsplash-min.jpg"
          />
        </div>

        <div className="menu-cont">
          <div className="menu-cont-fade" />
          <MenuDej isAutenticated={this.props.isAutenticated} />
        </div>

        <div></div>
      </div>
    );
  }
}

export default Dej;
