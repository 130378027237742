import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Perso extends Component {
  componentDidMount() {
    this.props.scrollUp();
  }

  render() {
    return (
      <div className="perso-cont">
        <div className="side-menu" />

        <div className="perso-header">
          <h1>
            SOIRÉE
            <br />
            <span>PERSO</span>
          </h1>
          <img
            alt=""
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/reserv.jpg"
          />
        </div>

        <div className="perso-info-cont">
          <h3>Vous organisez un événement!?</h3>
          <h3>Louez notre restaurant!</h3>

          <h5>
            Notre resto est disponible soirs et fins de semaine pour tous vos
            événements
          </h5>

          <ul className="perso-list">
            <li>
              <div>
                <img
                  alt=""
                  src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/confetti.svg"
                />
              </div>
              <h3>Band disponible sur demande</h3>
            </li>
            <li style={{ marginLeft: 75 }}>
              <div>
                <img
                  alt=""
                  src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/menu.svg"
                />
              </div>
              <h3>Menu sur mesure</h3>
            </li>
            <li style={{ marginLeft: 150 }}>
              <div>
                <img
                  alt=""
                  src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/clock.svg"
                />
              </div>
              <h3>Horaire variable</h3>
            </li>
          </ul>

          <button onClick={() => this.props.history.push("/contact")}>
            Contact
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Perso);
