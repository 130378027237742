import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { apiCall } from '../../api';

class Auth extends Component{
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: ''
    }
  }

  componentDidMount(){
    if(this.props.isAutenticated){
      this.props.history.push('/');
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.isAutenticated!==this.props.isAutenticated&&this.props.isAutenticated){
      this.props.history.push('/');
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  login = async () =>{
    try {
      let data = {
        username: this.state.username,
        password: this.state.password
      };

      let auth = await apiCall('post', '/api/auth',data);

      this.props.setUser(auth);
      this.props.history.push('/')

      // localStorage.setItem("jwtToken", auth.token);

    } catch (err) {
      alert(err.message)
    }
  }

  render(){
    return(
      <div className="auth-cont">
        <h1>Boss mode</h1>

        <div className="auth-input-cont">
          <h5>Username:</h5>
          <input
            className="boss-create-input"
            type="text"
            name="username"
            value={this.state.username}
            onChange={this.handleChange}
            />
        </div>
        <div className="auth-input-cont">
          <h5>Password:</h5>
          <input
            className="boss-create-input"
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.handleChange}
            />
        </div>
        <button onClick={this.login}>Connect!</button>
      </div>
    )
  }
}

export default withRouter(Auth);
