import React, { Component } from 'react';

import { apiCall } from '../../api';


class BossContact extends Component{
  constructor(props){
    super(props);
    this.state = {
      mailList: []
    }
  }

  componentDidMount(prevProps,prevState){
    this._mounted = true;
    this.getEmailList();
  }

  componentWillUnmount(){
    this._mounted = false
  }

  getEmailList = async () => {
    let emailList = await apiCall('get', '/api/contact');
    if(this._mounted){
      this.setState({
        mailList: emailList[0].list
      })
    }
  }

  render(){
    console.log(this.state.mailList)
    return(
      <div className="boss-contact">
        <h1>Liste de contact client</h1>
        <a href={`mailto:${this.state.mailList}`}><h5>Cliquez ici</h5></a>

      </div>
    )
  }
}

export default BossContact;
