import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Auth from '../boss/Auth';
import BossMain from '../boss/BossMain';
import Landing from './Landing';
import Dej from './Dej';
import Repas from './Repas';
import Boite from './Boite';
import Perso from './Perso';
import Contact from './Contact';

class Main extends Component{
  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      dej: null,
      diner: null,
      boite: null,
      brevage: null,
      extra: null
    }
  }



  scrollUp = () => {
    window.scrollTo(0,0);
    if(this.myRef.current){
      this.myRef.current.scrollTo(0,0);
    }
  }



  render(){

    return(
      <div ref={this.myRef} className="main">

        <Switch>

          <Route exact path="/" render={props =>
              <Landing scrollUp={this.scrollUp}/>
            }/>
          <Route exact path="/menu/dej" render={props =>
              <Dej scrollUp={this.scrollUp} isAutenticated={this.props.isAutenticated}/>
            }/>
          <Route exact path="/menu/diner" render={props =>
              <Repas scrollUp={this.scrollUp} isAutenticated={this.props.isAutenticated}/>
            }/>
          <Route exact path="/menu/bols" render={props =>
              <Boite scrollUp={this.scrollUp} isAutenticated={this.props.isAutenticated}/>
            }/>
          <Route exact path="/perso" render={props =>
              <Perso scrollUp={this.scrollUp}/>
            }/>
          <Route exact path="/contact" render={props =>
              <Contact scrollUp={this.scrollUp}/>
            }/>
          <Route path="/cestmoileboss" render={props =>
              <BossMain reloadMenu={this.getMenu} isAutenticated={this.props.isAutenticated} scrollUp={this.scrollUp} {...this.props}/>
            }/>
          <Route exact path="/boss" render={props =>
              <Auth setUser={this.props.setUser} isAutenticated={this.props.isAutenticated} {...this.props}/>
            }/>
        </Switch>

      </div>
    )
  }
}

export default withRouter(Main);
