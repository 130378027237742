import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  };

  goTo = url => {
    this.props.history.push(url);
    this.setState({
      menuOpen: false
    });
  };

  render() {
    return (
      <div className="navbar">
        <div
          className={`navbar-gen ${
            this.state.menuOpen === true ? "open" : null
          }`}
        >
          <img
            onClick={() => this.goTo("/")}
            alt="LeNikoli"
            src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/NIKOLI_Cuisine_Familiale_blanc.png"
          />
          <ul>
            {/* <li onClick={() => this.goTo("/menu/dej")}>
              <h3>Menu Déjeuner</h3>
            </li> */}
            <li onClick={() => this.goTo("/menu/diner")}>
              <h3>Menu Dîner</h3>
            </li>
            <li onClick={() => this.goTo("/menu/bols")}>
              <h3>Bols à Pat</h3>
            </li>
            <li onClick={() => this.goTo("/perso")}>
              <h3>Soirée personnalisée</h3>
            </li>
            <li onClick={() => this.goTo("/contact")}>
              <h3>Contact</h3>
            </li>
          </ul>
          <FontAwesomeIcon
            icon="bars"
            onClick={this.toggleMenu}
            className="menu-icon"
          />
        </div>

        {this.props.isAutenticated ? (
          <div className="nav-boss">
            <h3>BOSS MODE</h3>
            <ul>
              <li onClick={() => this.goTo("/cestmoileboss/menu")}>
                <h3>Menu</h3>
              </li>
              <li onClick={() => this.goTo("/cestmoileboss/contenu")}>
                <h3>Contenu</h3>
              </li>
              <li onClick={() => this.goTo("/cestmoileboss/contact")}>
                <h3>Email</h3>
              </li>
            </ul>
            <div className="boss-log-out" onClick={this.props.logOut}>
              <FontAwesomeIcon icon="power-off" />
              <h5>Logout</h5>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Nav);
