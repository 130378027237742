import React, {Component} from "react";

export default function withAuth(ComponentToBeRendered) {
  class Authenticate extends Component {
    componentWillMount() {
      if(this.props.isAutenticated === false) {
        this.props.history.push("/boss");
      }
    }
    componentWillUpdate(nextProps) {
      if(nextProps.isAutenticated === false) {
        this.props.history.push("/boss");
    }
  }
  render() {
    return <ComponentToBeRendered {...this.props} />;
  }
}

return Authenticate;
}
